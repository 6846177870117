import styled from "styled-components"

import { colorScale, mColors } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

export type MBadgeProps = {
  children: string
  size?: "small" // add support for other sizes if necessary
  color?: "info" | "error" | "neutral" | "good" | "warning" | "promotional"
  borderRadius?: "round" | "square"
  border?: boolean
}

export function MBadge({
  children: text,
  color = "info",
  borderRadius = "round",
  border = false,
}: MBadgeProps) {
  return (
    <MBadgeBox
      $color={color}
      $size={"small"} // hardcoded to small; add support for other sizes if necessary
      $borderRadius={borderRadius}
      $border={border}
    >
      {text}
    </MBadgeBox>
  )
}

/* A remapping of MBadgeProps to include the $ prefix required for styled-components. */
type MBadgeBoxProps = {
  [Prop in keyof Omit<MBadgeProps, "children"> as `$${Prop}`]: MBadgeProps[Prop]
}

const MBadgeBox = styled.div<MBadgeBoxProps>`
  display: inline-block;
  text-align: center;
  word-break: normal;
  font-weight: ${fontWeight.medium};

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return `
          padding: ${spacing.XS3} ${spacing.XS};
          font-size: 0.875rem;
        `
    }
  }}

  ${({ $color, $border }) => {
    switch ($color) {
      case "info":
        return `
          background: ${mColors.systemInfoLight};
          color: ${mColors.systemInfoDark};
          border: ${$border ? `1px solid ${mColors.systemInfoDark}` : `1px solid ${mColors.systemInfoLight}`};
        `
      case "error":
        return `
          background: ${mColors.systemErrorLight};
          color: ${mColors.systemErrorDark};
          border: ${$border ? `1px solid ${mColors.systemErrorDark}` : `1px solid ${mColors.systemErrorLight}`};
      `
      case "neutral":
        return `
          background: ${mColors.neutralDark};
          color: ${mColors.textSecondary};
          border: ${$border ? `1px solid ${mColors.textSecondary}` : `1px solid ${mColors.neutralDark}`};
      `
      case "good":
        return `
          background: ${mColors.systemGoodLight};
          color: ${mColors.systemGoodDark};
          border: ${$border ? `1px solid ${mColors.systemGoodDark}` : `1px solid ${mColors.systemGoodLight}`};
      `
      case "warning":
        return `
          background: ${mColors.systemWarningLight};
          color: ${mColors.systemWarningDark};
          border: ${$border ? `1px solid ${mColors.systemWarningDark}` : `1px solid ${mColors.systemWarningLight}`};
      `
      case "promotional":
        return `
          background: ${colorScale.hejmo[700]};
          color: ${mColors.textContrast};
          border: ${$border ? `1px solid ${mColors.textContrast}` : `1px solid ${colorScale.hejmo[700]}`};
      `
    }
  }}

${({ $borderRadius }) => {
    switch ($borderRadius) {
      case "round":
        return `
          border-radius: 50px;
        `
      case "square":
        return `
          border-radius: 5px;
      `
    }
  }}
`
